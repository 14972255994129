import { useNavigate } from "react-router-dom";
// import Logo from "../../assets/image/logo_color.svg";
import { useState, useEffect, useRef } from "react";
import InputText from "../../components/InputText";
import Rodape from "../../components/Rodape";
import IMask from "imask";
import InputMask from 'react-input-mask';
import { validarCPF } from "../../utils/cpfUtils";
import NewDatePicker from "../../components/DatePicker";
import { format } from "date-fns";

import LogoColor from "../../assets/image/logo_color.svg";

//vc tem que importar o componente para poder utilizar ele na pagina, com isso vc pode usar o mesmo item em mais de um lugar  
import Dropdown from "../../components/Dropdown";
import validarPassaporte from "../../utils/passaporte";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CadastroAcompanhante = ({ tipoTurismo, identificacaoTitular }) => {

    const notify = () => toast.warn('Preencha os dados do Acompanhante!', {
        position: "top-right", // Posição da notificação
        autoClose: 3000,       // Fecha automaticamente após 3 segundos
        hideProgressBar: false, 
        closeOnClick: true,    
        pauseOnHover: true,    
        draggable: true,       
        progress: undefined,   
    });

    const navigation = useNavigate();

    const [responsavel, setResponsavel] = useState(true);
    const [nome, setNome] = useState("");
    const [sobrenome, setSobrenome] = useState("");
    const [sexoAcom, setSexoAcompanhante] = useState("");
    const [nascimento, setNascimento] = useState("");
    const [cpf, setCpf] = useState("");
    const [cpfInvalido, setCpfInvalido] = useState(false);;
    const [passaporte, setPassaporte] = useState('');
    const [passaporteInvalido, setPassaporteInvalido] = useState(false);
    const [nacionalidadeAcom, setNacionalidade] = useState("");
    const [tipoTurismoState, setTipoTurismoState] = useState("");
    const [listaAcompanhante, setlistaAcompanhante] = useState([]);
    const [escolaridade, setEscolaridade] = useState("");
    const [tipoIdAcomp, setTipoIdAcomp] = useState("cpf");
    const [tipoAcomp, setTipoAcomp] = useState("Esposo(a)");
    const [telefoneacomp, setTelefoneAcomp] = useState("");
    const [newTelefoneacomp, setNewTelefoneAcomp] = useState("");
    const [emailacomp, setEmailAcomp] = useState("");
    const [isAllFilledAcom, setIsAllFilled] = useState(false);
    const [isKid, setIsKid] = useState(false);


    useEffect(() => {
        const areFieldsFilled = (fields) => fields.every((field) => !!field);
    
        if (isKid) {
            setCpf(`Menor: ${nome} ${sobrenome}`);
    
            const requiredFieldsKid = [telefoneacomp, sexoAcom, nome, sobrenome, nascimento, nacionalidadeAcom];
            setIsAllFilled(areFieldsFilled(requiredFieldsKid));
        } else {
            
            const commonFields = [telefoneacomp, sexoAcom, nome, sobrenome, nascimento, nacionalidadeAcom, tipoIdAcomp];
            if (tipoIdAcomp === "cpf") {
                setIsAllFilled(areFieldsFilled([...commonFields, cpf]));
            } else if (tipoIdAcomp === "passaporte") {
                setIsAllFilled(areFieldsFilled([...commonFields, passaporte]));
            } else {
                setIsAllFilled(false); 
            }
        }
    }, [telefoneacomp, sexoAcom, nome, sobrenome, nascimento, nacionalidadeAcom, tipoIdAcomp, cpf, passaporte, identificacaoTitular, isKid]);
    


    const handleAddLista = () => {

        if(!isAllFilledAcom){
            notify();
            return
        }

        const index = listaAcompanhante.findIndex((item) => 
            (cpf && item.cpf === cpf) && (!cpf && item.passaporte === passaporte)
        );
        
        setTelefoneAcomp(newTelefoneacomp);
        setNewTelefoneAcomp("");

        if (index !== -1) {
            listaAcompanhante[index].nome = nome;
            listaAcompanhante[index].sobrenome = sobrenome;
            listaAcompanhante[index].sexo = sexoAcom;
            listaAcompanhante[index].data_nascimento = nascimento;
            listaAcompanhante[index].cpf = cpf;
            listaAcompanhante[index].nacionalidadeAcom = nacionalidadeAcom;
            listaAcompanhante[index].escolaridade = escolaridade;
            listaAcompanhante[index].tipoIdAcomp = tipoIdAcomp;
            listaAcompanhante[index].tipoAcomp = tipoAcomp;
            listaAcompanhante[index].emailAcomp = emailacomp;
            listaAcompanhante[index].telefoneAcomp = telefoneacomp;
            listaAcompanhante[index].passaporte = passaporte;
        } else {
            
            listaAcompanhante.push({
                nome: nome,
                sobrenome: sobrenome,
                sexo: sexoAcom,
                data_nascimento: nascimento,
                cpf: cpf,
                nacionalidadeAcom: nacionalidadeAcom,
                escolaridade: escolaridade,
                tipoIdAcomp: tipoIdAcomp,
                tipoAcomp: tipoAcomp,
                emailAcomp: emailacomp,
                telefoneAcomp: telefoneacomp,
                passaporte: passaporte
            });
        }

        localStorage.setItem("@cadastro:Etapa3", JSON.stringify(listaAcompanhante))
        setNome("");
        setSobrenome("");
        setSexoAcompanhante("");
        setNascimento("");
        setCpf("");
        setNacionalidade("");
        setEscolaridade("");
        setTipoIdAcomp("");
        setEmailAcomp("");
        setTipoAcomp("");
        setTelefoneAcomp("+55")
        
        setIsAllFilled(false);
        setIsKid(false);
    };

    const opcoes = [
        { key: 1, value: 'Sem Escolaridade' },
        { key: 2, value: 'Ensino Fundamental' },
        { key: 3, value: 'Ensino Medio' },
        { key: 4, value: 'Ensino Superior' },
        { key: 5, value: 'Graduado' },
        { key: 6, value: 'Mestrado' },
        { key: 7, value: 'Doutorado' }
    ];

    const opcoes2 = [
        { key: 1, value: "Avô(ó)" },
        { key: 2, value: "Esposo(a)" },
        { key: 3, value: "Filho(a)" },
        { key: 4, value: "Irmão(ã)" },
        { key: 5, value: "Mãe" },
        { key: 6, value: "Pai" },
        { key: 7, value: "Tio(a)" }
    ];

    const opcoes3 = [
        { key: 1, value: 'Brasileiro(a)' },
        { key: 2, value: 'Estrangeiro(a)' },
    ];


    const handleOptionChange = (e) => {
        setSexoAcompanhante(e.target.value);
    }

    const handlealterar = (e) => {
        const cpf = e.target.value;
        for (let index = 0; index < listaAcompanhante.length; index++) {
            const element = listaAcompanhante[index];
            if (element.cpf === cpf) {
                setNome(element.nome);
                setSobrenome(element.sobrenome);
                setSexoAcompanhante(element.sexo);
                setNascimento(element.data_nascimento);
                setCpf(element.cpf);
                setPassaporte(element.passaporte);
                setNacionalidade(element.nacionalidadeAcom);
                setEscolaridade(element.escolaridade);
                setTipoIdAcomp(element.tipoIdentificacao);
                setTipoAcomp(element.tipoAcomp);
                setEmailAcomp(element.emailAcomp);
                setTelefoneAcomp(element.telefoneAcomp);
            }
        }
    }



    const handleExcluir = () => {
        const index = listaAcompanhante.findIndex(item => item.cpf === cpf);
        if (index !== -1) {
            listaAcompanhante.splice(index, 1);

            localStorage.setItem("@cadastro:Etapa3", JSON.stringify(listaAcompanhante))
            setNome("");
            setSobrenome("");
            setSexoAcompanhante("");
            setNascimento("");
            setCpf("");
            setPassaporte("");
            setNacionalidade("");
            setEscolaridade("");
            setTipoIdAcomp("");
            setEmailAcomp("");
            setTelefoneAcomp("");
            setNewTelefoneAcomp("");
            setTipoAcomp("");
        }
    }


    useEffect(() => {
        setTipoTurismoState(tipoTurismo)
        const interval = setInterval(() => {
            (async () => {
                const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa3"));
                if (dados != null) {
                    setlistaAcompanhante(dados);
                }
            })();
        }, 1000);
        return () => clearInterval(interval);
    }, [tipoTurismo, tipoTurismoState]);

    const handleCpfChangeAcompanhante = (event) => {
        const novoCpfAcompanhante = event.target.value;
        setCpf(novoCpfAcompanhante);
        setCpfInvalido(!validarCPF(novoCpfAcompanhante));
    }

    const handlePassaporteChangeAcompanhante = (event) => {
        const novoPassaporte = event.target.value;
        setPassaporte(novoPassaporte);
        setPassaporteInvalido(!validarPassaporte(novoPassaporte));
    }

    // const maskTelAcomRef = useRef(null);

    // const maskOptionsTelefone = {
    //     mask: "+00 (00) 00000-0000"
    // }

    // useEffect(() => {
    //     if(telefoneacomp){
    //         maskTelAcomRef.current = IMask(
    //         document.getElementById("telefoneAcom"),
    //         maskOptionsTelefone
    //       )
    //     }
    //   }, [telefoneacomp])
    

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="containercadastro" style={{ alignItems: 'center' }} onSubmit={() => (listaAcompanhante.length === 0 || responsavel) ? navigation("/contato") : alert("É Preciso que marque a opção de responsabilidade sobre os acompanhantes.")}>
                <div>
                    <div className="text1-black">Acompanhantes</div>

                    <div className="corpoacom ">
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '2rem'}}>
                        <input
                            type="checkbox" value={isKid} onChange={item => setIsKid(item.target.checked)}
                        />
                        <div className="titleinput" style={{margin: 'auto 0'}}>Seu acompanhante é menor de 14 anos?</div>
                    </div>
                    {
                        !isKid ? 
                        (
                            <>
                                <div>
                                    <div className="titleinput">Documentação</div>
                                    <div className="col-2 flex">                            
                                        <div>
                                            <input defaultChecked type="radio" value="cpf" name="ident" checked={tipoIdAcomp === "cpf"} onChange={item => setTipoIdAcomp(item.target.value)} />
                                            <label className="textRadio">CPF</label>
                                        </div>
                                        <div>
                                            <input type="radio" value="passaporte" name="ident" checked={tipoIdAcomp === "passaporte"} onChange={item => setTipoIdAcomp(item.target.value)} />
                                            <label className="textRadio">Passaporte</label>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                        :
                        (<></>)
                    }

                        

                        
                        <div className="containerbloco" class="col-5 flex">
                            {
                                !isKid ? 
                                (
                                    <>
                                        <div>
                                            {
                                                tipoIdAcomp === "cpf" &&

                                                <InputMask
                                                    title="CPF"
                                                    className="cpf-input"
                                                    placeholder="Número do CPF"
                                                    mask="999.999.999-99"
                                                    maskChar="_"
                                                    type="text"
                                                    id="acompanhanteCPF"
                                                    style={{
                                                        fontFamily: "Poppins-Regular",
                                                        height: '5.6rem',
                                                        width: '97%',
                                                        marginBottom: '2rem',
                                                        border: '1px solid #b0b0b0',
                                                        borderRadius: '5.7rem',
                                                        color: '#666666',
                                                        fontSize: '1.6rem',
                                                        paddingLeft: '1rem',
                                                    }}
                                                    name="cpf"
                                                    value={cpf}
                                                    onChange={handleCpfChangeAcompanhante}
                                                />
                                            }
                                                {cpfInvalido && tipoIdAcomp === "cpf"? (
                                                    <p style={{ color: "red" }}>CPF inválido. Por favor, verifique.</p>
                                                ): (<></>)}

                                                {tipoIdAcomp === "passaporte" &&
                                                    <InputText
                                                        placeholder="Número do passaporte"
                                                        value={passaporte}
                                                        onchange={handlePassaporteChangeAcompanhante}
                                                        msnWarning="Campo precisa ser preenchido"
                                                    />
                                                }
                                            {passaporteInvalido && tipoIdAcomp === "passaporte"? (
                                                <p style={{ color: "red" }}>Passaporte inválido. Por favor, verifique.</p>
                                            ): (<></>)}
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <></>
                                )
                            }

                            

                            <Dropdown
                                data={opcoes3}
                                placeholder="Nacionalidade"
                                onchange={item => setNacionalidade(item.target.value)}
                                value={nacionalidadeAcom}
                                msnWarning="Campo obrigatório."
                                required={true}
                            />


                        </div>

                        
                        

                        <div className="containerbloco" class="col-5 flex">
                            <InputText
                                title="Nome"
                                placeholder="Primeiro nome"
                                value={nome}
                                onchange={item => setNome(item.target.value)}
                                msnWarning="Obrigatório preencher o primeiro nome."
                            />

                            <InputText
                                title="Sobrenome"
                                placeholder="Segundo nome"
                                value={sobrenome}
                                onchange={item => setSobrenome(item.target.value)}
                                msnWarning="Obrigatório preencher o segundo nome"
                            />

                        </div>

                        <div style={{ display: 'flex', gap: '2rem', alignItems: 'flex-start' }}>
  
                        {/* Parentesco
                        { 
                            tipoTurismoState === "Turismo De Excursão" ? 
                            (
                                <></>
                            )
                            :
                            (
                                <>
                                    <div className="containerbloco">
                                            <div className="textInput">Parentesco</div>
                                            <div className="flex">
                                            <div>
                                                <input
                                                type="radio"
                                                value="Esposo(a)"
                                                name="ident2"
                                                checked={tipoAcomp === "Esposo(a)"}
                                                onChange={(item) => setTipoAcomp(item.target.value)}
                                                />
                                                <label style={{ marginRight: '2rem' }} className="textRadio">
                                                Esposo(a)
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                type="radio"
                                                value="Filho(a)"
                                                name="ident2"
                                                checked={tipoAcomp === "Filho(a)"}
                                                onChange={(item) => setTipoAcomp(item.target.value)}
                                                />
                                                <label className="textRadio">Filho(a)</label>
                                            </div>
                                            </div>
                                    </div>
                                </>
                            )
                        } */}
                        

                        {/* Qual seu sexo */}
                        <div  className="containerbloco" >
                            <div className="textInput">Qual seu sexo</div>
                            <div className="col-2 flex">
                            <div>
                                <input
                                type="radio"
                                value="Masculino"
                                name="sexo"
                                checked={sexoAcom === "Masculino"}
                                onChange={(item) => setSexoAcompanhante(item.target.value)}
                                />
                                <label className="textRadio">Masculino</label>
                            </div>
                            <div>
                                <input
                                type="radio"
                                value="Feminino"
                                name="sexo"
                                checked={sexoAcom === "Feminino"}
                                onChange={(item) => setSexoAcompanhante(item.target.value)}
                                />
                                <label className="textRadio">Feminino</label>
                            </div>
                            <div>
                                <input
                                type="radio"
                                value="Outros"
                                name="sexo"
                                checked={sexoAcom === "Outros"}
                                onChange={(item) => setSexoAcompanhante(item.target.value)}
                                />
                                <label className="textRadio">Outros</label>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="containerbloco flex" style={{width: '100%', gap: '20px'}}>


                            <div className="box" style={{flex: '0 0 40%'}}>

                                <div className="textInput">Data de nascimento</div>
                                <NewDatePicker onchange={newValue => {
                                    const formattedDate = format(newValue, 'yyyy-MM-dd');
                                    setNascimento(formattedDate); // Atualiza o state com o novo valor
                                }}
                                />
                                {/* <InputText
                                    type="date"
                                    value={nascimento}
                                    onchange={(item) => setNascimento(item.target.value)}
                                    msnWarning="Campo precisa ser preenchido."
                                /> */}

                            </div>

                            <div className="box" style={{flex: '0 0 60%'}}>

                                <div className="textInput">Telefone(whatsapp)</div>
                                {/* <InputText
                                    id="telefoneAcom"
                                    placeholder="+00 (00) 00000-0000"
                                    mask="+00 (00) 00000-0000"
                                    type="text"
                                    value={newTelefoneacomp}
                                    onchange={(item) => setNewTelefoneAcomp(item.target.value)}
                                    msnWarning="Obrigatório preencher o primeiro nome."
                                /> */}

                                {/* <InputMask
                                        mask="(99) 99999-9999" // Máscara para celular no Brasil
                                        value={telefoneacomp}
                                        onChange={(e) => setTelefoneAcomp(e.target.value)}
                                    >
                                        {() => <input className="input" type="text" placeholder="Digite o telefone" />}
                                </InputMask> */}
                                <PhoneInput 
                                    country={'br'}
                                    value={telefoneacomp}
                                    onChange={(e) => setTelefoneAcomp(e)}
                                    containerStyle={{
                                        borderRadius: '100px'
                                    }}
                                    inputStyle={{
                                        height: '50px', // Altura do campo
                                        fontSize: '14px', // Tamanho da fonte
                                        paddingLeft: '40px', // Ajuste para acomodar a bandeira
                                        width: '100%'
                                    }}
                                    placeholder=""
                                />

                            </div>

                        </div>

                        {/* <div>
                            <InputText
                                title="E-mail (ao digitar seu email, o sistema criara uma conta de acesso na sllips)"
                                placeholder="Digite o E-mail"
                                value={emailacomp}
                                onchange={item => setEmailAcomp(item.target.value)}
                                required={false}
                            />
                        </div> */}
                       


                    </div>


                    <div className="btngroup" >
                        <button className="btn deletar" type="button" onClick={() => handleExcluir()}>Deletar</button>
                        
                        {
                            isAllFilledAcom ? 
                            (
                                <button className="btn salvar" type="button" onClick={() => handleAddLista()}>
                                    Adicionar
                                </button>
                            )
                            :
                            (
                                <button className="btn disabled" type="button" onClick={() => handleAddLista()}>
                                    Adicionar
                                </button>
                            )
                        }
                    </div>

                </div>

                
                <div className="containerbloco" >
                    <div className="linhadivisor" />

                    <div className="titleinput">Acompanhante(s)</div>

                    {listaAcompanhante.map((item) => (
                        // <input key={index} className="input" type="text" value={item.nome} disabled />
                        <div className="listaAcompanhante flex" key={item.cpf}>
                            <input type="radio" name="lista" value={item.cpf} onChange={handlealterar} />
                            <label className="textInput maiuscula">{item.nome} {item.sobrenome} | {item.cpf ?? ''}</label>
                        </div>
                    ))}

                </div>

            </div>
            <ToastContainer />
        </div>
    )
}

export default CadastroAcompanhante;
