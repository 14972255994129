import { useEffect, useRef, useState } from "react";
import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Rodape from "../rodape";
import { BuscaClientes_Cidades } from "../../models/buscaCidades";
import { useAuth } from "../../service/JwtContext";
import { autentication } from "../../utils/config";
import BtnTipoCadastro from "../../components/BtnTipoCadastro";
import { FiMenu, FiX, FiLogOut, FiLogIn, FiSearch } from "react-icons/fi";
import InputText from "../../components/InputText";
import API from "../../service/api";
import { useNavigate } from "react-router-dom";
import { StatusBar } from "../../components/StatusBar";
import { InputMask } from "imask";

import Logobanner from "../../assets/image/logobanner.svg";

const dataFeeds = [
  {
    id: 1,
    nome: "Wesley Leno",
    descricao: "Expetacular, facilita muito a sua viagem",
    nome_imagem: "avatar1",
  },
  {
    id: 2,
    nome: "Ezequiel Alef",
    descricao: "Me ajudou muito no momento de embarcar em minha viagem",
    nome_imagem: "avatar2",
  },
  {
    id: 3,
    nome: "Ana Cláudia",
    descricao: "A agilidade na hodra de embarcar em minha viagem",
    nome_imagem: "avatar3",
  },
];

const Destino = () => {
  const [cidades, setCidades] = useState();
  const [cadastrado, setCadastrado] = useState(false);
  const [parceiros, setParceiros] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenlogin, setIsOpenLogin] = useState(false);
  const [autenticado, setAutenticado] = useState(false);
  const [nome, setNome] = useState();
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [destino, setDestino] = useState();
  const [feeds, setFeeds] = useState(dataFeeds);

  const carousel = useRef();
  const [width, setwidth] = useState(0);

  const { auth, user } = useAuth();
  const navigation = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setLogin(false);
  };

  const handleLogin = () => {
    setIsOpenLogin(!isOpenlogin);
  };

  const AutenticacaoWebSite = async () => {
    try {
      await auth.login(autentication.login, autentication.password);
    } catch (e) {
      console.log("Deu erro", e);
    }
  };

  async function handleAvancaLogin(e) {
    e.preventDefault();
    setMessage("");

    try {
      //const { data : response } = await API.get(`/visitantes/${email}/${password}`);

      const { data: response } = await auth.login(
        autentication.login,
        autentication.password
      );

      if (response) {
        if (response._id > 0) {
          setLogin(false);

          //navigation("/cadastro");
        } else {
          setMessage(response?.message);
        }
      } else {
        console.log("Login efetuado : ", response);
        setMessage(response);
        setLogin(true);
      }
    } catch (error) {
      console.log("Falaha: ", error);
    }
  }

  useEffect(() => {
    setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth);

    const dados = JSON.parse(localStorage.getItem("@perfil:Atualizar"));

    console.log("dados: ", user);

    if (dados != null) {
      setEmail(dados.email);
      setPassword(dados.password);
    }

    const buscaCidades = async () => {
      // const res = await BuscaClientes_Cidades();
      const res = await API.get("/cidades");

      if (res) {
        setCidades(res.data);
      }
    };

   // AutenticacaoWebSite();

    buscaCidades();
  }, []);

  return (
    <section className="container">
      <div>
        <div className="titulogrande">Qual será seu próximo destino?</div>
      </div>

      <div class="pesquisar">
        <div className="text">
          <div className="textpesquisatitulo">
            Conheça lugares incriveis!
            <div className="textpesquisasubtitulo">Vamos explorar juntos</div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "20px",
            marginBottom: "4.2rem",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              required={false}
              className="inputCard"
              type="text"
              placeholder="Digite o nome do seu destino"
              value={destino}
              onChange={(e) => setDestino(e.target.value)}
              style={{
                width: "300px",
                height: "40px",
                border: "1px solid #d3d2d2",
                borderRadius: "2.7rem",
                backgroundColor: "#f8f8f8",
                paddingRight: "40px", // Espaço para o ícone
              }}
            />
            <FiSearch
              style={{
                position: "absolute",
                right: "10px", // Ajusta o ícone no canto direito
                color: "#888",
                pointerEvents: "none", // Para evitar interferir na interação com o input
              }}
              size={20}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {cidades && (
          <div>
            <motion.div
              ref={carousel}
              className="carousel"
              whileTap={{ cursor: "grabbing" }}
            >
              <motion.div
                drag="x"
                dragConstraints={{ right: 0, left: -width }}
                className="inner flexmunicipio"
              >
                {cidades?.map((item) => (
                  <Link className="box" to={`/novo/${item.id}/${item.cidade}`}>
                    <motion.div className="item" key={item.id}>
                      <div className="corpo-municipio">
                        <div className="btnTpMunicipio">
                          <div
                            className="Tabmunicipio"
                            style={{
                              minWidth: "300px",
                              minHeight: "100px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center", // Centraliza verticalmente os itens
                            }}
                          >
                            {/* Título e descrição */}
                            <div
                              style={{
                                margin: "2rem",
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                className="btnTitulo"
                                style={{ marginTop: "10px" }}
                              >
                                {item.nome}
                              </div>
                              <div className="btnDescricao">{item.Estado.nome}</div>
                            </div>

                            {/* Imagem pequena */}
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  item?.nome
                                    ? `/imagens/img_${(item.nome).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}.png`
                                    : "/imagens/img_capitolio.png"
                                }
                                alt="Imagem pequena"
                                style={{
                                  width: "40%", // Ajuste a largura da imagem
                                  height: "50%", // Ajuste a altura da imagem
                                  objectFit: "cover", // Garante que a imagem seja ajustada proporcionalmente
                                  borderRadius: "0 15% 15% 0", // Opcionais: para uma imagem circular
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </Link>
                ))}
              </motion.div>
            </motion.div>
          </div>
        )}
      </div>

      {/*banner*/}

     

      <Rodape />
    </section>
  );
};
export default Destino;
