import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getYear } from 'date-fns';
import { styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import IconButton from '@mui/material/IconButton';
import { PickersMonth } from '@mui/x-date-pickers/MonthCalendar/PickersMonth';



const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  fontSize: '1.8rem', // Tamanho maior para os meses e anos
}));

const StyledMonth = styled(PickersMonth)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  fontSize: '1.8rem'
}));

const StyledDay = styled(PickersDay)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  fontSize: '1.8rem'
}));

export default function NewDatePicker({ onchange }) {

  const currentYear = getYear(new Date());
  

  return (
    
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker 
        format="dd/MM/yyyy"
        label="Selecione a data"
        openTo="year"
        views={['year', 'month', 'day']}
        slots={{
          openPickerButton: StyledButton,
        }}
        slotProps={{
          openPickerIcon: {
            fontSize: 'large'
          },
          textField: {
            variant: 'outlined',
            InputProps: {
              sx: { fontSize: '1.8rem' }, // Aumenta o tamanho do texto
            },
            InputLabelProps: {
              sx: { fontSize: '1.8rem' }, // Aumenta o rótulo
            },
          },
          toolbar:{
            sx:  { fontSize: '1.8rem' }, // Aumenta o título da barra
          },
          popper:{
            style: {
              fontSize: '2rem'
            }
          },
          day: {
            style: {
              fontSize: '2rem'
            },
          },
          monthButton: {
            style: {
              fontSize: '2rem'
            }
          },
          yearButton: {
            style: {
              fontSize: '2rem'
            }
          },
          
        }}
        onChange={onchange}
      />
    </LocalizationProvider>
  
  );
}
