
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo_color.svg";
import Dropdown from "../../components/Dropdown";
import Rodape from "../../components/Rodape";
import { useState, useEffect, useRef } from "react";
import InputText from "../../components/InputText";
import IMask from "imask";


const CadastroContato = () => {

    const navigation = useNavigate();

    const [cidade, setCidade] = useState("");
    const [email, setEmail] = useState("");
    const [telefoneEmer, setTelefoneEmer] = useState("");
    const [contato, setContato] = useState("");
    const [parentesco, setParentesco] = useState("");
    const [emergenciaTel, setEmergenciaTel] = useState("");




    const opcoes = [
        { key: 1, value: "Pai" },
        { key: 2, value: "Mãe" },
        { key: 3, value: "Irmão(a)" },
        { key: 4, value: "Filho(a)" },
        { key: 5, value: "Tio(a)" },
        { key: 6, value: "Avô(ó)" },
        { key: 7, value: "Esposa" },
        { key: 8, value: "Marido" }
    ];

    const tipoVeiculo = [
        { key: 1, value: "Motocicleta" },
        { key: 2, value: "Carro" },
        { key: 3, value: "Onibus" },
        { key: 4, value: "MotorHome" },
    ]


    const handleAvanca = () => {
        localStorage.setItem("@cadastro:Etapa4", JSON.stringify({
            cidade,
            email,
            telefoneEmer,
            nome:contato,
            parentesco,
            emergenciaTel
        }));
    }

    useEffect(() => {
        // const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa4"));
        localStorage.setItem("@cadastro:Etapa4", JSON.stringify({
            cidade,
            email,
            telefoneEmer,
            nome:contato,
            parentesco,
            emergenciaTel
        }));
        

        // if (dados != null) {            

        //     setCidade(dados.cidade);
        //     setEmail(dados.email);
        //     setTelefone(dados.telefone);
        //     setContato(dados.nome);
        //     setParentesco(dados.parentesco);
        //     setEmergenciaTel(dados.emergenciaTel)
        // }
    }, [telefoneEmer, parentesco, contato, emergenciaTel]);

    // const maskOptions = {
    //     mask: "+{00} (000) 00000 0000"
    // }
    // const maskRef = useRef(null);

    // useEffect(() => {       
       
    //     maskRef.current = IMask(document.getElementById("emergenciaTel"), maskOptions);
    //     maskRef.current = IMask(document.getElementById("telefone"), maskOptions);
           
    // }, [maskOptions, emergenciaTel, telefoneEmer]);

    return (
        <>
            <div className="corpo flex">
                {/* <div className="text1-black marginTop">Contato de emergência</div>
                <div className="text2">É importante ter um número emergencial cadastrado.</div>

                <InputText
                    title="Nome"
                    placeholder="Nome do contato"
                    value={contato}
                    onchange={item => setContato(item.target.value)}
                    msnWarning="Informe o nome do contato."
                    required={true}
                />

                <div className="textInput">Parentesco</div>
                <Dropdown
                    data={opcoes}
                    placeholder=""
                    onchange={item => setParentesco(item.target.value)}
                    value={parentesco}
                    msnWarning="Informe seu parentesco com o contato."
                    required={true}
                />

                <InputText
                    id="emergenciaTel"
                    title="Telefone de contato"
                    placeholder="+{00} (000) 00000 0000"
                    value={emergenciaTel}
                    onchange={item => setEmergenciaTel(item.target.value)}
                    msnWarning="Informe o telefone do contato."
                    required={true}
                /> */}
        
            </div>

            <Rodape
                voltar={() => navigation("/acompanhante")}
                avancar={true}
                valorBar={83}
            />
        </>
    )
}
export default CadastroContato;