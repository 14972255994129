import * as Yup from 'yup';

const validaCampos = Yup.object().shape({
  id_municipio: Yup.number().required('O campo id_municipio é obrigatório'),
  tipo_turismo: Yup.string().required('O campo tipo de turismo é obrigatório'),
  viajando_com: Yup.string().required('O campo viajando com é obrigatório'),
  conhece_municipio: Yup.boolean().required('O campo conhece municipio é obrigatório'),
  data_chegada: Yup.date().required('O campo data chegada é obrigatório'),
  data_partida: Yup.date().required('O campo data partida é obrigatório'),
  visitante: Yup.object().shape({
    nome: Yup.string().required('O campo nome é obrigatório'),
    sobrenome: Yup.string().required('O campo sobrenome é obrigatório'),
    tipoIdentificacao: Yup.string().required('O campo tipo Identificacao é obrigatório'),
    // identificacao: Yup.string().required('O campo identificacao é obrigatório'),
    data_nascimento: Yup.date().required('O campo data nascimento é obrigatório'),
    sexo: Yup.string().required('O campo sexo é obrigatório'),
    // escolaridade: Yup.string().required('O campo escolaridade é obrigatório'),
    compartilha_dados: Yup.boolean().required('O campo compartilha dados é obrigatório'),
    pais: Yup.string().required('O campo pais é obrigatório'),
    //cidade: Yup.string().required('O campo cidade é obrigatório'),
    email: Yup.string().email('Email inválido').required('O campo email é obrigatório'),
    telefone: Yup.string().required('O campo telefone é obrigatório'),
    // nacionalidade: Yup.string().required('O campo nacionalidade é obrigatório'),
    //password: Yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres').required('O campo password é obrigatório'),
  }),
  // acomodacao: Yup.object().shape({
  //   tipo: Yup.string().required('O campo tipo de acomodação é obrigatório'),
  //   nome: Yup.string().required('O campo nome da acomodação é obrigatório'),
  // }),
  // transporte: Yup.object().shape({
  //   tipo: Yup.string(),
  //   placa: Yup.string(),
  // }),
  // emergencia: Yup.object().shape({
  //   nome: Yup.string().required('O campo nome da emergência é obrigatório'),
  //   parentesco: Yup.string().required('O campo parentesco é obrigatório'),
  //   telefone: Yup.string().required('O campo telefone da emergência é obrigatório'),
  // }),
  passagem: Yup.object().shape({
    hospedado_outro_municipio: Yup.boolean().required('O campo hospedado_outro_municipio é obrigatório'),
    nao_hospedado: Yup.boolean().required('O campo não hospedado é obrigatório'),
  }),
  // pet: Yup.object().shape({
  //   aceita_pet: Yup.string().required('O campo aceita pet é obrigatório'),
  // }),
  local_reserva: Yup.string().required('O campo local_reserva é obrigatório'),
  agencia: Yup.string(),
  tipo_turismo: Yup.string().required('O campo tipo de turismo é obrigatório'),
  tipo_roteiro: Yup.string().required('O campo tipo de roteiro é obrigatório'),
});

export default validaCampos;
