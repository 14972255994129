import { QRCodeCanvas } from "qrcode.react";
import { useNavigate, useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useRef, useState } from "react"; // Importa o useState
import API from "../../service/api";

import BackgroundImage from "../../assets/image/500x600.jpg";

const CadastroConcluido = () => {
  const [isPopupVisible, setPopupVisible] = useState(true); // Torna o popup visível automaticamente
  const { id, chegada, partida } = useParams();
  const navigation = useNavigate();

  const regex = /^(\d{4})-(\d{2})-(\d{2})/;
  const data1 = regex.exec(chegada);
  const data2 = regex.exec(partida);

  const dadosCadastro = JSON.parse(localStorage.getItem("@cadastro:Etapa4"));

  const elementoCapturado = useRef(null);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const gerarEDownloadPDF = async () => {
    const elemento = elementoCapturado.current;
    const doc = new jsPDF();

    const dataHoraAtual = new Date().toLocaleString("pt-BR", {
      timeZone: "America/Sao_Paulo",
      dateStyle: "short",
      timeStyle: "short",
    });

    const dataHoraFormatada = dataHoraAtual.replace(",", "_");

    const nomeArquivo =
      "Viagem_ID_" + id + "_Data_" + chegada + "_" + dataHoraFormatada;

    try {
      // Captura o elemento como uma imagem em formato PNG
      const canvas = await html2canvas(elemento);
      const imagemPNG = canvas.toDataURL("image/png");

      var imgWidth = 150;
      var imgHeight = 297;

      // Adiciona a imagem ao documento PDF
      doc.addImage(imagemPNG, 30, 0, imgWidth, imgHeight);

      // Obtém o texto do elemento capturado
      const textoElemento = elemento.innerText.trim();

      const nomeArquivoPDF = nomeArquivo.replace(/[^a-zA-Z0-9]/g, "") + ".pdf";
      doc.save(nomeArquivoPDF);

      const nomeArquivoPNG = nomeArquivo.replace(/[^a-zA-Z0-9]/g, "") + ".png";

      const blobPNG = await fetch(imagemPNG).then((res) => res.blob());

      // Crie um objeto FormData e anexe o Blob a ele para upload
      const formDataPNG = new FormData();
      formDataPNG.append("arquivo", blobPNG, nomeArquivoPNG);

      const responsePNG = await API.post("/upload", formDataPNG, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Se o envio do arquivo for bem-sucedido, envie a mensagem com o URL do arquivo

      const urlArquivo = "https://sllips.com.br/api/uploads/" + nomeArquivoPNG; // Substitua 'url' pelo nome do campo que contém a URL do arquivo no objeto de resposta

      // Envio da mensagem com o URL do arquivo

      try {
        const responseMensagem = await API.post("/sendmensagemarq", {
          telefone: `${dadosCadastro.telefone}`,

          mensagem: "Segue o QRCdode Sllips.com.br",

          urlArq: urlArquivo,
        });
      } catch (error) {
        //console.error('Erro ao gerar e enviar o arquivo:', error);
      }

      // Se o envio do PDF for bem-sucedido, faça o download do PDF
      // Este passo dependerá do método de download específico usado em seu aplicativo
    } catch (error) {
      console.error("Erro ao gerar e enviar o arquivo:", error);
    }
  };

  return (
    <div className="fundoConcluido">
     {/* Popup */}


   {/*
      {isPopupVisible && (
        <div className="popupOverlay" onClick={togglePopup}>
          <div
            className="popupContent"
            onClick={(e) => e.stopPropagation()} // Impede o fechamento ao clicar no conteúdo
          >
            <button onClick={togglePopup} className="closePopupX">
              &times; 
            </button>

            <div className="popupImage">
              <img
                src={BackgroundImage}
                alt="Imagem do Popup"
                className="backgroundImage"
              />
            </div>

            <div className="popupBox">
              <div className="popupText">
                <h3>Me conte mais sobre a viagem!</h3>
              </div>
              <button className="popupButton">Clique aqui</button>
            </div>
          </div>
        </div>
      )}
          */}

      

      <div id="concluido" className="flex">
        <div ref={elementoCapturado} className="subcontainer">
          <div className="subcontainer2">
            <div className="tituloqr">
              <strong>sllips</strong>
            </div>
            <div className="subtituloqr">
              Destino seguro,
              <br /> experiência completa
            </div>
          </div>

          <div className="subcontainerqr">
            <div className="descricaoqr">
              Apresente seu QR-Code nos estabelecimentos parceiros Sllips.
            </div>

            <div>
              <QRCodeCanvas
                value={id}
                size={172}
                bgColor={"#ffffff"}
                fgColor={"#222222"}
                level={"L"}
                includeMargin={false}
              />
            </div>

            <div className="grouptext">
              <div className="textqrcode">
                <strong>Viva essa experiência</strong>
              </div>
              <div className="validadeqrcode">
                <strong>VALIDADE DO QRCODE</strong>
              </div>
              <div>
                <div className="validadeqrcode2">
                  De {`${data1[3]}/${data1[2]}/${data1[1]}`} até{" "}
                  {`${data2[3]}/${data2[2]}/${data2[1]}`}
                </div>
              </div>
            </div>

            <div className="aviso">
              <strong>Esse QrCode é válido para os acompanhantes</strong>
            </div>
          </div>
          <button className="btn salvar" onClick={gerarEDownloadPDF}>
            Baixar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CadastroConcluido;
