export default function validarPassaporte(passaporte) {
    // Verifica se o passaporte tem entre 6 e 9 caracteres (comuns internacionalmente)
    // e contém apenas letras maiúsculas e dígitos
    const regex = /^[A-Z0-9]{6,9}$/;
  
    if (!passaporte) {
      return { valido: false, mensagem: "Passaporte não pode estar vazio." };
    }
  
    if (regex.test(passaporte)) {
      return { valido: true, mensagem: "Passaporte válido." };
    } else {
      return { valido: false, mensagem: "Formato inválido. Use letras maiúsculas e dígitos, entre 6 e 9 caracteres." };
    }
}