import { useNavigate } from "react-router-dom";
// import Logo from "../../assets/image/logo_color.svg";
import { useState, useEffect, useRef } from "react";
import InputText from "../../components/InputText";
import Rodape from "../../components/Rodape";
import API from "../../service/api";

import LogoColor from "../../assets/image/logo_color.svg";

import axios from 'axios';

import DropdownCity from "../../components/DropdownCity";
import DropdownUf from "../../components/DropdownUf";

import { MuiTelInput } from 'mui-tel-input'


//vc tem que importar o componente para poder utilizar ele na pagina, com isso vc pode usar o mesmo item em mais de um lugar 
import Dropdown from "../../components/Dropdown";

const Onibus = () => {

    const navigation = useNavigate();

    const [responsavel, setResponsavel] = useState(false);

    const [listaVeiculo, setlistaVeiculo] = useState([]);


    const [veiculo, setVeiculo] = useState("");
    const [placa, setPlaca] = useState("");

    const [adulto, setAdulto] = useState('');
    const [crianca, setCrianca] = useState('');
    const [total, setTotal] = useState('');

    const [estadouf, setEstadoUF] = useState('');
    const [pais, setPais] = useState('');
    const [cidade, setCidade] = useState("");

    const [acomodacao, setAcomodacao] = useState("");
    const [nomeAcomodacao, setNomeAcomodacao] = useState("");

    const [selectedUf, setSelectedUf] = useState("0");
    const [dataCidade, setDataCidade] = useState("");
    const [dataUf, setDataUf] = useState("");

    const [endereco, setEndereco] = useState("");
    const [telefone, setTelefone] = useState("");

    const [chegada, setChegada] = useState("");
    const [partida, setPartida] = useState("");

    const handleAddLista = () => {

        const index = listaVeiculo.findIndex(item => item.cidade === cidade);

        if (index !== -1) {
            listaVeiculo[index].veiculo = veiculo;
            listaVeiculo[index].placa = placa;
            listaVeiculo[index].adulto = adulto;
            listaVeiculo[index].crianca = crianca;
            listaVeiculo[index].total = total;
            listaVeiculo[index].estadouf = estadouf;
            listaVeiculo[index].pais = pais;
            listaVeiculo[index].cidade = cidade;
            listaVeiculo[index].acomodacao = acomodacao;
            listaVeiculo[index].nomeacomodacao = nomeAcomodacao;
            listaVeiculo[index].telefone = telefone;
            listaVeiculo[index].endereco = endereco;
            listaVeiculo[index].chegada = chegada;
            listaVeiculo[index].partida = partida;


        } else {
            listaVeiculo.push({

                veiculo: veiculo,
                placa: placa,
                crianca: crianca,
                adulto: adulto,
                total: total,
                chegada: chegada,
                partida: partida,
                estadouf: estadouf,
                pais: pais,
                cidade: cidade,
                acomodacao: acomodacao,
                nomeacomodacao: nomeAcomodacao,
                telefone: telefone,
                endereco: endereco,

            });

        }


        localStorage.setItem("@agencia:Etapa2", JSON.stringify(listaVeiculo))
        setVeiculo("");
        setPlaca("");
        setAdulto("");
        setCrianca("");
        setTotal("");
        setEstadoUF("");
        setPais("");
        setCidade("");
        setAcomodacao("");
        setNomeAcomodacao("");
        setEndereco("");
        setTelefone("");
        setChegada("");
        setPartida("");

    };

    const tipoVeiculo = [
        { key: 1, value: "Onibus" },
        { key: 2, value: "Micro Onibus" },
        { key: 3, value: "Van" },
    ]

    const tipoHospedagem = [
        { key: 1, value: "Não hospedado no município" },
        { key: 2, value: "Pousada" },
        { key: 3, value: "Hotel" },
        { key: 4, value: "Rancho" },
    ]




    const handlealterar = (e) => {
        const cidade = e.target.value;
        for (let index = 0; index < listaVeiculo.length; index++) {
            const element = listaVeiculo[index];
            if (element.cidade === cidade) {

                setVeiculo(element.veiculo);
                setPlaca(element.placa);
                setAdulto(element.adulto);
                setCrianca(element.crianca);
                setTotal(element.total);
                setEstadoUF(element.estadouf);
                setPais(element.pais);
                setCidade(element.cidade);
                setAcomodacao(element.acomodacao);
                setNomeAcomodacao(element.nomeacomodacao);
                setEndereco(element.endereco);
                setTelefone(element.telefone);
                setChegada(element.chegada);
                setPartida(element.partida);
            }
        }
    }



    const handleExcluir = () => {
        const index = listaVeiculo.findIndex(item => item.cidade === cidade);
        if (index !== -1) {
            listaVeiculo.splice(index, 1);

            localStorage.setItem("@agencia:Etapa2", JSON.stringify(listaVeiculo))

            setVeiculo("");
            setPlaca("");
            setAdulto("");
            setCrianca("");
            setTotal("");
            setEstadoUF("");
            setPais("");
            setCidade("");
            setAcomodacao("");
            setNomeAcomodacao("");
            setEndereco("");
            setTelefone("");
            setChegada("");
            setPartida("");

        }
    }


    useEffect(() => {
        const interval = setInterval(() => {
            (async () => {
                const dados = JSON.parse(localStorage.getItem("@agencia:Etapa2"));
                if (dados != null) {
                    setlistaVeiculo(dados);
                }
            })();
        }, 1000);

        if(adulto || crianca){

            setTotal(Number(adulto) + Number(crianca))
        }

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {

        if(adulto || crianca){

            setTotal(Number(adulto) + Number(crianca))
        }
    }, [adulto,crianca]);

    //////////////////////// Rotina buscar cidade e estado //////////////////////////

    useEffect(() => {
        if (selectedUf === "0") {
            return;
        }
        try {
            axios
                .get(
                    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`
                )
                .then((response) => {
                    setDataCidade(response.data);

                });

        }
        catch (err) {
            console.log("Erro ao buscar dados da api: ", err);
        }

    }, [selectedUf]);

    useEffect(() => {
        axios
            .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
            .then((response) => {
                setDataUf(response.data);
            });
    }, [pais]);

    function handleSelectUf(value) {
        setSelectedUf(value);
        setEstadoUF(value);
    }

    function handleSelectCity(value) {
        setCidade(value);
    }

    const TelefoneMask = (value, info) => {

        if (value.length <= 17) {
            setTelefone(value);
        }
    }




    return (
        <form className="container" onSubmit={() => (responsavel) ? navigation("/GuiaAgencia") : alert("É Preciso que marque a opção de responsabilidade sobre os veículos.")}>

            <section className="home_topo_conteudo">
                <img src={LogoColor} alt="logo" className="logoColor" />
                <img src={LogoColor} alt="logo" className="logo" />
            </section>


            <div className="corpo flex">
                <div>
                    <div className="text1-black">Cadastro do veículo</div>
                    <div className="text2">Não há necessidade de preencher caso seu companheiro(a) já tenha inserido as informações.<strong> (SOMENTE PARA ESPOSO(A) E FILHOS)</strong></div>

                    <div className="textInput">Tipo de tranporte</div>

                    <Dropdown
                        data={tipoVeiculo}
                        placeholder=""
                        msnWarning="Informe o transporte utilizado."
                        value={veiculo}
                        onchange={item => setVeiculo(item.target.value)}

                    />

                    <InputText
                        title="Placa"
                        placeholder="Ex: ABC-1234"
                        value={placa}
                        onchange={item => setPlaca(item.target.value)}
                        msnWarning="Informe a placa do veiculo"
                    />

                    <div className="titleinput">Quantidade de pessoas embarcadas</div>

                    <div className="containerbloco" class="col-4 flex">


                        <InputText
                            title="Qtd. adultos"
                            placeholder=""
                            value={adulto}
                            onchange={(item) => setAdulto(item.target.value)}
                            msnWarning="Informe quantos adultos embarcados."
                            //required={true}
                        />

                        <InputText
                            title="Qtd. crianças"
                            placeholder=""
                            value={crianca}
                            onchange={(item) => setCrianca(item.target.value)}
                            msnWarning="Informe quantas criaças embarcadas."
                            //required={true}
                        />

                        <InputText
                            title="Total"
                            placeholder=""
                            value={total}
                            //required={true}
                        />


                    </div>

                    <div className="TitleCampo">Qual o seu período de permanência?</div>
                    <div className="col-2- flex">
                        <fieldset className="borda-legenda">
                            <legend className="legenda">Chegada</legend>
                            <input  className="data" type="date" value={chegada} onChange={(item) => setChegada(item.target.value)} />
                        </fieldset>

                        <fieldset className="borda-legenda">
                            <legend className="legenda">Partida</legend>
                            <input  className="data" type="date" value={partida} onChange={(item) => setPartida(item.target.value)} />
                        </fieldset>
                    </div>

                    <div className="titleinput">Destino de viagem:</div>


                    <InputText
                        title="País?"
                        placeholder="País"
                        value={pais}
                        onchange={(item) => setPais(item.target.value)}
                        msnWarning="Informe o País de destino"
                        //required={true}
                    />

                    <div className="textInput"> Estado </div>
                    <DropdownUf
                        data={dataUf}
                        placeholder="Selecionar Estado"
                        onchange={(event) => handleSelectUf(event.target.value)}
                        value={estadouf}
                        msnWarning="Informe o Estado do destino."
                        //required={true}
                    />

                    {estadouf &&
                        <>
                            <div className="textInput"> Cidade </div>

                            <DropdownCity
                                data={dataCidade}
                                placeholder="Selecionar Cidade"
                                onchange={(event) => handleSelectCity(event.target.value)}
                                value={cidade}
                                msnWarning="Informe o cidade de destino."
                                //required={true}
                            />
                        </>
                    }

                    {/* <div className="titleinput">Onde você vai se hospedar?</div>
                    <div className="textInput">Tipo de hospedagem</div>
                    <Dropdown
                        data={tipoHospedagem}
                        placeholder=""
                        msnWarning="Ïnforme o tipo de hospedagem."
                        value={acomodacao}
                        onchange={item => setAcomodacao(item.target.value)}
                        //required={true}
                    />


                    <InputText
                        title="Nome da Hospedagem"
                        placeholder="Ex: Hotel ...,  Pousada...,"
                        value={nomeAcomodacao}
                        onchange={item => setNomeAcomodacao(item.target.value)}
                        msnWarning="Informe o nome da hospedagem"
                        //required={true}
                    />

                    <InputText
                        title="Endereço da Hospedagem"
                        placeholder="Av. Avelino..."
                        value={endereco}
                        onchange={item => setEndereco(item.target.value)}
                        msnWarning="Informe o endereço da hospedagem"
                        //required={true}
                    />



                    <div className="textInput"> Contato da Hospedagem</div>

                    <InputText
                        id="telefone"
                        variant="outlined"
                        placeholder="(000) 00000 0000"
                        defaultCountry="BR"
                        value={telefone}
                        onChange={TelefoneMask}
                        flagSize="medium"
                        forceCallingCode


                        inputProps={
                            {
                                style: { fontSize: 15, border: 10 }
                            }
                        }

                    /> */}


                    <div className="btngroup">
                        <button className="btn deletar" type="button" onClick={() => handleExcluir()}>Deletar</button>
                        <button className="btn salvar" type="button" onClick={() => handleAddLista()}>Salvar</button>
                    </div>
                </div>

                <div className="linhadivisor" />

                <div className="titleinput">Veículo(s) cadastrado(s)</div>

                {listaVeiculo.map((item, index) => (
                    // <input key={index} className="input" type="text" value={item.nome} disabled />
                    <div className="listaVeiculo flex" key={item.cidade}>
                        <input type="radio" name="lista" value={item.cidade} onChange={handlealterar} />
                        <label className="textInput maiuscula" >{item.veiculo} {item.placa} | Destino: {item.cidade}</label>
                    </div>
                ))}

                <div className="linhadivisor" />

                <div className="containerPolitica flex">
                    <input type="checkbox" className="checkbox" checked={responsavel} onChange={item => setResponsavel(item.target.checked)} />
                    <div className="politica">Eu me declaro responsável por todos o(s) veículo(s) e passageiro(s).</div>
                </div>

            </div>

            <Rodape
                voltar={() => navigation(-1)}
                avancar={true}
                valorBar={90}
            />
        </form>
    )
}
export default Onibus;
