import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo_color.svg";
import Dropdown from "../../components/Dropdown";
import { useEffect, useRef, useState } from "react";
import Rodape from "../../components/Rodape";
import InputText from "../../components/InputText";
import IMask, { MaskElement } from "imask";
import { format } from "date-fns";

import LogoColor from "../../assets/image/logo_color.svg";
import { BuscaVeiculoAgenciaByCNPJ } from "../../models/buscaAgencia";
import CadastroAcomTrans from "../CadastroAcomTrans";
import { BuscaEstados } from "../../models/buscaEstados";
import { BuscaMunicipios } from "../../models/buscaMunicipios";
import CadastroContato from "../ContatoPassagem"

import DropdownPaises from "../../components/DropdownPaises";
import { paises } from "../../utils/paises";
import DropdownUf from "../../components/DropdownUf";
import DropdownCity from "../../components/DropdownCity";
import axios from "axios";

import { validarCPF } from "../../utils/cpfUtils";
import NewDatePicker from "../../components/DatePicker";
import validarPassaporte from "../../utils/passaporte";

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const CadastroVisitante = () => {
  const navigation = useNavigate();

  const [nome, setNome] = useState("");
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [identificacao, setIdentificacao] = useState("");
  const [passaporte, setPassaporte] = useState("");
  const [passaporteInvalido, setPassaporteInvalido] = useState("");
  const [cnpjEmpresa, setCnpjEmpresa] = useState("");
  const [cpfInvalido, setCpfInvalido] = useState(false);
  const [tipoIdentificacao, setTipoIdentificacao] = useState("cpf");
  const [sexo, setSexo] = useState("");
  const [escolaridade, setEscolaridade] = useState("");
  const [compartilhaDados, setCompartilhaDados] = useState(true);
  const [nascimento, setNascimento] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [veiculoAgencia, setVeiculoAgencia] = useState([]);

  const [cnpj, setCnpj] = useState(null);

  const [tipoTurismo, setTipoTurismo] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [veículoempresa, setVeículoEmpresa] = useState("");

  const [embarque, setEmbarque] = useState("");

  const [estado, setEstado] = useState("");
  const [telefone, setTelefone] = useState("");
  const [veiculo, setVeiculo] = useState("");

  const [dataEstado, setDataEstado] = useState([]);
  const [placa, setPlaca] = useState("");

  const [selectedUf, setSelectedUf] = useState("0");
  const [dataCidade, setDataCidade] = useState("");
  const [dataUf, setDataUf] = useState("");

  const [cidade, setCidade] = useState("");
  const [estadouf, setEstadoUF] = useState("");
  const [pais, setPais] = useState("");

  const [isAllFilledVis, setIsAllFilledVis] = useState(false)

  const tipoVeiculo = [
    { key: 1, value: "Aéreo" },
    { key: 2, value: "Automóvel" },
    { key: 3, value: "Motocicleta" },
    { key: 4, value: "MotorHome" },
    { key: 5, value: "Ônibus de Excursão" },
    { key: 6, value: "Transporte Rodoviário" },
  ];

  useEffect(() => {
    localStorage.setItem(
      "@cadastro:Etapa2",
      JSON.stringify({
        visitante: {
          nome: nome,
          sobrenome: sobrenome,
          nacionalidade,
          tipoIdentificacao,
          identificacao,
          sexo,
          passaporte,
          data_nascimento: nascimento,
          escolaridade,
          compartilha_dados: compartilhaDados,
          email,
          password: newpassword,
          empresa:nomeEmpresa,
          cnpj: cnpjEmpresa,
          veículo_empresa: veículoempresa,
          tipo_turismo: tipoTurismo,
          embarque: embarque,
          veiculo: veiculo,
          placa: placa,
          pais,
          estado,
          cidade,
          telefone,
        },
      })
    );
  }, [
    nome,
    sobrenome,
    nacionalidade,
    tipoIdentificacao,
    nascimento,
    identificacao,
    sexo,
    passaporte,
    escolaridade,
    compartilhaDados,
    email,
    newpassword,
    empresa,
    cnpjEmpresa,
    veiculo,
    placa,
    pais,
    cidade,
    estado,
    telefone,
    tipoTurismo,
    embarque,
    veículoempresa,
    nomeEmpresa
  ]);

  //Verifica se CPF/Passaporte Cidade/Estado estão preenchidos
  useEffect(() => {
    

    if (
      !nome || !sobrenome || !sexo || !nascimento || 
      !telefone || !email || !nacionalidade
    ) {
      // Dados obrigatórios gerais ausentes
      setIsAllFilledVis(false);
    } else {
      if (nacionalidade === "Brasileiro(a)") {
        setPais("Brasil");
    
        // Para brasileiros, verificar cidade e estado
        if (!cidade || !estadouf) {
          setIsAllFilledVis(false);
          return; // Evita processar condições adicionais
        }
      }
    
      if (tipoIdentificacao === "cpf" && !identificacao) {
        setIsAllFilledVis(false);
        return;
      }
    
      if (tipoIdentificacao === "passaporte" && !passaporte) {
        setIsAllFilledVis(false);
        
        return;
      }
    
      // Se todas as condições foram atendidas
      setIsAllFilledVis(true);
    }
  },[tipoIdentificacao, identificacao, passaporte, nacionalidade, estado, cidade, email, nascimento, sexo, nome, sobrenome, telefone, isAllFilledVis])

  useEffect(() => {
    if (selectedUf === "0") {
      return;
    }
    try {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`
        )
        .then((response) => {
          setDataCidade(response.data);
        });
    } catch (err) {
      console.log("Erro ao buscar dados da api: ", err);
    }
  }, [selectedUf]);;

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
      .then((response) => {
        setDataUf(response.data);
      });
  }, [pais]);

  function handleSelectUf(value) {
    setSelectedUf(value);
    setEstadoUF(value);
  }

  function handleSelectCity(value) {
    setCidade(value);
  }

  useEffect(() => {
    const fetchdata = async () => {
      setVeiculoAgencia([]);
      const res = await BuscaVeiculoAgenciaByCNPJ(cnpj);

      if (res.veiculo.length > 0) {
        setVeiculoAgencia(res.veiculo);
      }
    };
    if (cnpj && cnpj.length > 11) {
      fetchdata();
    }
  }, [cnpj]);

  useEffect(() => {
    const fetchdata = async () => {
      const res = await BuscaEstados();
      if (res.length > 0) {
        setDataEstado(res);
      }
    };
    fetchdata();
  }, []);

  useEffect(() => {
    const fetchdata = async () => {
      const res = await BuscaMunicipios(estado);
      if (res.length > 0) {
        setDataCidade(res);
      }
    };
    if (estado && estado.length > 0) {
      fetchdata();
    }
  }, [estado]);

  const handleVoltar = async () => {
    try {
      if (compartilhaDados) {
        localStorage.setItem(
          "@cadastro:Etapa2",
          JSON.stringify({
            visitante: {
              nome: nome,
              sobrenome,
              nacionalidade,
              tipoIdentificacao,
              identificacao,
              passaporte,
              sexo,
              data_nascimento: nascimento,
              escolaridade,
              compartilha_dados: compartilhaDados,
              email,
              password: newpassword,
              empresa:nomeEmpresa,
              cnpj,
              veículo_empresa: veículoempresa,
              tipo_turismo: tipoTurismo,
              embarque: embarque,
              veiculo: veiculo,
              placa: placa,
              pais,
              estado,
              cidade,
              telefone,
            },
          })
        );

        navigation(-1);
      } else {
        alert("Aceite os Termos e Condições para prosseguir.");
      }
    } catch (error) {
      console.log("caiu no erro: ", error);
    }
  };

  useEffect(() => {
    const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));

    if (dados != null) {
      setCidade(dados.cidade);
      setEstadoUF(dados.estadouf);
      setPais(dados.pais);
    }
  }, []);

  useEffect(() => {
    //pega os dados salvos na pagina anterior
    const aux = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));

    //atribui o valor do tipo turismo na pagina anterior a variavel local
    setTipoTurismo(aux.tipo_turismo);
  }, [tipoTurismo]);

  const opcoes = [
    { key: 1, value: "Sem Escolaridade" },
    { key: 2, value: "Ensino Fundamental" },
    { key: 3, value: "Ensino Medio" },
    { key: 4, value: "Ensino Superior" },
    { key: 5, value: "Graduado" },
    { key: 6, value: "Mestrado" },
    { key: 7, value: "Doutorado" },
  ];

  const opcoes2 = [
    { key: 1, value: "Onibus 1" },
    { key: 2, value: "Onibus 2" },
    { key: 3, value: "Onibus 3" },
    { key: 4, value: "Onibus 4" },
  ];

  const opcoes3 = [
    { key: 1, value: "Brasileiro(a)" },
    { key: 2, value: "Estrangeiro(a)" },
  ];

  const maskOptions = {
    mask: "000.000.000-00",
  };

  const maskRef = useRef(null);
  const maskTelRef = useRef(null);

  useEffect(() => {
    if (tipoIdentificacao === "cpf") {
      maskRef.current = IMask(
        document.getElementById("visitanteCPF"),
        maskOptions
      );
    }
  }, []);

  const maskOptionsCnpj = {
    mask: "00.000.000/0000-00",
  };

  const maskOptionsTelefone = {
    mask: "+00 (00) 00000-0000"
  }

  useEffect(() => {
    if (cnpjEmpresa) {
      maskRef.current = IMask(
        document.getElementById("CNPJEmpresa"),
        maskOptionsCnpj
      );
    }
  }, [cnpjEmpresa]);

  // useEffect(() => {
  //   if(telefone){
  //     maskTelRef.current = IMask(
  //       document.getElementById("telefoneVis"),
  //       maskOptionsTelefone
  //     )
  //   }
  // }, [telefone])
 

  const handleNomeEmpresaChange = (event) => {
    const novoempresa = event.target.value;
    setNomeEmpresa(novoempresa);
  };

  const handleCnpjEmpresaChange = (event) => {
    const cnpj = event.target.value;
    setCnpjEmpresa(cnpj);
  };

  const handleCpfChange = (event) => {
    const novoCpf = event.target.value;
    setIdentificacao(novoCpf);
    setCpfInvalido(!validarCPF(novoCpf));
  };

  const handlePassaporteChange = (event) => {
    const novoPassaporte = event.target.value;
    setPassaporte(novoPassaporte);
    setPassaporteInvalido(!validarPassaporte(novoPassaporte));
  };

  return (
    <form className="container" onSubmit={handleVoltar}>
      <section className="home_topo_conteudo">
        <img src={LogoColor} alt="logo" className="logoColor" />
        <img src={LogoColor} alt="logo" className="logo" />
      </section>

      <section class="corpo flex">
        <div className="text1-black">Cadastro do visitante</div>
        <div className="text2">
          Preencha os dados do perfil. Isso levará alguns segundos.
          <br />
          <strong>
            Depois ganhe tempo na visitação dos pontos turísticos!
          </strong>
        </div>

        <div className="containerbloco">
          
          {
            tipoTurismo === "Turismo De Excursão" ? (
              <>
                <div className="text1-black">Informações da Empresa</div>

                <div className="col-5">
                  <InputText
                    title="Nome da empresa"
                    id="NomeEmpresa"
                    placeholder="Nome da Empresa"
                    value={nomeEmpresa}
                    onchange={handleNomeEmpresaChange}
                    msnWarning="Campo precisa ser preenchido"
                    required={true}
                  />

                  <InputText
                    title="CNPJ da Empresa"
                    id="CNPJEmpresa"
                    placeholder="CNPJ da Empresa"
                    value={cnpjEmpresa}
                    onchange={handleCnpjEmpresaChange}
                    msnWarning="Campo precisa ser preenchido"
                    required={true}
                  />
                </div>
                
                
              </>
            )
            :
            (
              <></>
            )
          }
          <h2 className="titleinput">Documentação</h2>

          <div className="col-2 flex">
            <div>
              <input
                type="radio"
                value="cpf"
                name="ident"
                checked={tipoIdentificacao === "cpf"}
                onChange={(item) => setTipoIdentificacao(item.target.value)}
              />
              <label className="textRadio">CPF</label>
            </div>
            <div>
              <input
                type="radio"
                value="passaporte"
                name="ident"
                checked={tipoIdentificacao === "passaporte"}
                onChange={(item) => setTipoIdentificacao(item.target.value)}
              />
              <label className="textRadio">Passaporte</label>
            </div>
          </div>

          <div className="containerbloco" class="col-5 flex">
            {tipoIdentificacao === "cpf" && (
              <div>
                <div className="textInput">CPF</div>
                <InputText
                  id="visitanteCPF"
                  placeholder="Número do CPF"
                  mask={maskOptions}
                  value={identificacao}
                  onchange={handleCpfChange}
                  msnWarning="Campo precisa ser preenchido"
                />
                {cpfInvalido && tipoIdentificacao === "cpf" ? (
                  <p style={{ color: "red" }}>CPF inválido. Por favor, verifique.</p>
                )
                :
                (<></>)
                }
              </div>
            )}

            {tipoIdentificacao === "passaporte" && (
              <div>
                <div className="textInput">Passaporte</div>
                <InputText
                  placeholder="Número do passaporte"
                  value={passaporte}
                  onchange={handlePassaporteChange}
                  msnWarning="Campo precisa ser preenchido"
                />
                {passaporteInvalido && tipoIdentificacao === "passaporte" ? (
                  <p style={{ color: "red" }}>Passaporte inválido. Por favor, verifique.</p>
                )
                :
                (<></>)
                }
              </div>
            )}

            <div>
              <div className="textInput">Nacionalidade</div>
              <Dropdown
                data={opcoes3}
                placeholder=""
                onchange={(item) => setNacionalidade(item.target.value)}
                value={nacionalidade}
                msnWarning="Campo obrigatório."
                required={true}
              />
            </div>
          </div>
        </div>

        <div className="col-5">
          <InputText
            title="Nome"
            placeholder="Primeiro nome"
            value={nome}
            onchange={(item) => setNome(item.target.value)}
            msnWarning="Obrigatório preencher o primeiro nome."
            required={true}
          />
          <InputText
            title="Sobrenome"
            placeholder="Segundo nome"
            value={sobrenome}
            onchange={(item) => setSobrenome(item.target.value)}
            msnWarning="Obrigatório preencher o segundo nome"
            required={true}
          />
        </div>

        <div className="containerbloco">
          <div className="titleinput">Qual seu sexo</div>
          <div className="col-2 flex">
            <div>
              <input
                type="radio"
                value="Masculino"
                name="sexo"
                checked={sexo === "Masculino"}
                onChange={(item) => setSexo(item.target.value)}
              />
              <label className="textRadio">Masculino</label>
            </div>
            <div>
              <input
                type="radio"
                value="Feminino"
                name="sexo"
                checked={sexo === "Feminino"}
                onChange={(item) => setSexo(item.target.value)}
              />
              <label className="textRadio">Feminino</label>
            </div>
            <div>
              <input
                type="radio"
                value="Outros"
                name="sexo"
                checked={sexo === "Outros"}
                onChange={(item) => setSexo(item.target.value)}
              />
              <label className="textRadio">Outros</label>
            </div>
          </div>
        </div>

        <div className="containerbloco" class="col-5 flex">
          <div>
            <div className="textInput">Data de Nascimento</div>
            <NewDatePicker 
              onchange={newValue => {
                  const formattedDate = format(newValue, 'yyyy-MM-dd');
                  setNascimento(formattedDate); // Atualiza o state com o novo valor
              }} 
          />
          </div>
          {/* <InputText
            type="date"
            title="Data de nascimento"
            value={nascimento}
            onchange={(item) => setNascimento(item.target.value)}
            msnWarning="Informe a data de nascimento."
            required={true}
          /> */}

          {/* <div>
            <div className="textInput">Escolaridade</div>
            <Dropdown
              title="Escolaridade"
              data={opcoes}
              placeholder=""
              onchange={(item) => setEscolaridade(item.target.value)}
              value={escolaridade}
              msnWarning="Campo obrigatório."
              required={true}
            />
          </div> */}
        </div>





        <div className="text1-black"> Contato </div>
        

        <div className="textInput"> Qual País você mora? </div>
        <DropdownPaises
          data={paises}
          placeholder="Selecionar o País"
          onchange={(event) => setPais(event.target.value)}
          value={pais}
          msnWarning="Informe o País que você mora."
          required={true} /* Campo de País continua obrigatório */
        />
        

        {pais === "Brasil" && 
          (
            <>
              <div className="textInput"> Qual Estado você mora? </div>
              <DropdownUf
                data={dataUf}
                placeholder="Selecionar Estado"
                onchange={(event) => handleSelectUf(event.target.value)}
                value={estadouf}
                msnWarning="Informe o Estado que você mora."
                required={
                  true
                } /* Estado é obrigatório apenas se o país for Brasil */
              />

              {estadouf && (
                <>
                  <div className="textInput"> Qual Cidade você mora? </div>
                  <DropdownCity
                    data={dataCidade}
                    placeholder="Selecionar Cidade"
                    onchange={(event) => handleSelectCity(event.target.value)}
                    value={cidade}
                    msnWarning="Informe a Cidade que você mora."
                    required={
                      true
                    } /* Cidade é obrigatória apenas se o país for Brasil */
                  />
                </>
              )}
              
            </>
          )
        }

        <div className="containerbloco">
          {/* <InputText
            id="telefoneVis"
            title="Telefone"
            type="text"
            placeholder="+00 (00) 00000 0000"
            value={telefone}
            onchange={(item) => setTelefone(item.target.value)}
          /> */}
          <div className="textInput">Telefone</div>
          <PhoneInput 
              country={'br'}
              value={telefone}
              onChange={(e) => setTelefone(e)}
              containerStyle={{
                  borderRadius: '100px'
              }}
              inputStyle={{
                  height: '50px', // Altura do campo
                  fontSize: '14px', // Tamanho da fonte
                  paddingLeft: '40px', // Ajuste para acomodar a bandeira
                  width: '100%'
              }}
          />
            <div style={{marginBottom: '4rem'}}></div>
          <InputText
            title="E-mail"
            placeholder="Digite o E-mail"
            value={email}
            onchange={(item) => setEmail(item.target.value)}
            msnWarning="Obrigatório preencher o email"
            required={true}
          />
          
        </div>
      </section>
      
      <CadastroContato />
      
      
      {/* ACOMPANHANTE */}
      
      <CadastroAcomTrans isAllFilledVis={isAllFilledVis} cpfTitular={identificacao ? identificacao : passaporte}/>
  
      <Rodape valorBar={100} voltar={() => handleVoltar(-1)} />
    </form>
  );
};

export default CadastroVisitante;
