import "../components/DropdownCidade.css";
import Warning from "../assets/image/warning.png";
import { capitalizeFirstLetter } from "../utils/strings";

const DropdownCidade = ({ data, placeholder, onchange, value, msnWarning, required}) => {
    return (
        <>
            {data &&
                <>
                    <select required={required} className="dropdown" onChange={onchange} defaultValue={value}>
                    <option value="" disabled selected>{placeholder}</option>

                    {data.map((item) => {
                        return <option className="option" key={item.codigo_ibge} value={item.codigo_ibge}>{capitalizeFirstLetter(item.nome)}</option>
                    })}

                    </select>
                    <div className="warning">
                        <img src={Warning} alt="ícone de alerta" />
                        {msnWarning}
                    </div>
                </>
            }
            
        </>
    )
}

export default DropdownCidade;
